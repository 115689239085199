import React, { useState } from 'react';
import MessageList from './MessageList';
import Message from './MessageBubble'; // Assuming you have a Message component

const ChatCanvas = ({ messageAry, config}) => {
  console.log("messageAry", messageAry)
  return (
    <div className="chat-app" >
      <MessageList messages={messageAry} config={config}/>
    </div>
  );
};

export default ChatCanvas;
