import './App.css';
import Login from './Auth/login';
import SignUp from './Auth/signup';
import InternalHome from './InternalHome/InternalHome';
import Upgrade from './Upgrade/Upgrade';
import Conversations from './Conversations/Conversations';
import ProfilePage from './ProfilePage/ProfilePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import ProtectedRoutes from './ProtechedRoutes/ProtectedRoutes'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/conversations/" element={<Conversations/>} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/profile/:id" element={<ProfilePage />} />
          <Route exact path="/upgrade/" element={<Upgrade />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
 