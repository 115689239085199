// MessageBubble.jsx
import React, { useState, useEffect, useRef } from 'react';
import styles from './CSS Modules/messagebubble.module.scss';
import { MdEditSquare } from 'react-icons/md';

const MessageBubble = ({ messageItem, config }) => {
  const { content, role } = messageItem;
  const [editedContent, setEditedContent] = useState([]);

  useEffect(() => {
    // Initialize editedContent with the initial content
    setEditedContent(content);
  }, [content]);

  const msgRef = useRef(null);



  return (
    <div className={role==="user" ? styles.user : styles.bot} style={{backgroundColor: role==="user" ? config.color: 'rgba(24, 24, 26, 0.4)'}}>
      
      {editedContent.map((item, index) => (
        <div key={index}>
          {item.type === 'text' && (
            <span
              ref={msgRef}
              suppressContentEditableWarning={true}
              dangerouslySetInnerHTML={{ __html: item.text.value }}
              className={styles.text}
              data-index={index}
            />
          )}
        </div>
      ))}
      {/* <p className={styles.role}>{`Role: ${role}`}</p>
      <button>Edit</button> */}
    </div>
  );
};

export default MessageBubble;
