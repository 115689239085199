import React, { useState, useEffect, useCallback } from 'react';
import { app, auth, db } from '../Auth/firebaseApp/firebaseApp';
import { getFirestore, collection, addDoc, getDoc, doc, query, getDocs, where } from "firebase/firestore";
import { Navigate } from "react-router-dom";
import Assistants from '../Assistants/Assistants';
import InternalHomeHeader from './InternalHomeHeader';
import Upgade from '../Upgrade/Upgrade';
import UFD from '../UFD/ufd'
const stripe = require('stripe')(process.env.REACT_APP_STRIPE_PK);

const InternalHome = (props) => {
    const [currentView, setCurrentView] = useState("home");
    const [userData, setUserData] = useState(null);
    const [accountData, setAccountData] = useState(null);
    const [assistants, setAssistants] = useState(null);
    const [subActive, setSubActive] = useState(false);
    const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);
    const [msg, setMessage]= useState(null)
    useEffect(() => {
        
        if (auth.currentUser) {
            fetchUserData(auth.currentUser.uid);
        }
    }, [auth])

    const updateCurrentView = (view) => {
        setCurrentView(view)
    }

    // const checkSubscription = async (sub_id, email, customer_id) => {
    //     // Retrieve the subscription details from Stripe
    //     const subscription = await stripe.subscriptions.retrieve(sub_id);
    //     const customer = await stripe.customers.retrieve(customer_id);
    //     // Check if the trial period has ended
    //     const trialEnd = subscription.trial_end;
    //     const currentTime = Math.floor(Date.now() / 1000);
    //     const daysRemaining = Math.ceil((trialEnd - currentTime) / 86400);

    //     //Paid But Still On Trial
    //     if (subscription.status === "trialing" && customer.invoice_settings.default_payment_method !== null) {
    //         setSubActive(true)
    //         return
    //     }
    //      //Active Subscription
    //      if (subscription.status === "active" && customer.invoice_settings.default_payment_method !== null) {
    //         setSubActive(true)
    //         return
    //     }

    //     //Subscription Trialing
    //     if (subscription.status === "trialing" && customer.invoice_settings.default_payment_method === null) {
    //         if (daysRemaining > 0) {
    //             setTrialDaysRemaining(daysRemaining)
    //             setMessage("You have "+daysRemaining+" remaining on your trial")
                
    //         }
    //     }
        
    //     if (subscription.status === "canceled" || subscription.status === "unpaid" || subscription.status === "past_due" || subscription.status === "incomplete_expired" || subscription.status === "incomplete"  ) {
    //         //window.location.href=process.env.REACT_APP_STRIPE_BILLING_LINK+"?prefilled_email="+email
    //         //window.localStorage.href="/"
    //     }
    // }


    const fetchUserData = async (uid) => {
        const q = query(collection(db, "users"), where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
        let data = []
     
        querySnapshot.forEach((doc) => {
            data.push(doc.data())
        });
        setUserData(data[0])
        fetchAccount(data[0].account_id)
        //fetchAssistants()
        //checkSubscription(data[0].subscriptionId, data[0].email, data[0].stripeCustomerId)
    };

    const fetchAccount = async(account_id)=>{
        const q = query(collection(db, "accounts"), where("docId", "==", account_id));
        const querySnapshot = await getDocs(q);
        let data = []
        querySnapshot.forEach((doc) => {
            data.push(doc.data())
        });
        if(data[0] && data[0].keyRef===""){
            alert("No API Key Detected")
        }
        console.log("Account",data)
        setAccountData(data[0])
        fetchAssistants(account_id)
    }



    const fetchAssistants = async (account_id) => {
        console.log(account_id)
        const q = query(collection(db, "assistants"), where("account_id", "==", account_id));
        const querySnapshot = await getDocs(q);
        let data = []
        querySnapshot.forEach((doc) => {
            data.push(doc.data())
        });
       
        setAssistants(data)
    };

    return (
        <div style={{height: '100vh', }}>
            {userData ?
                <React.Fragment>
                    {/* {trialDaysRemaining ?
                        <UFD msg={trialDaysRemaining}></UFD> : null
                    } */}

                    <InternalHomeHeader userData={userData}  subActive={subActive}/>
                    {currentView === "upgrade" ?
                        <Upgade></Upgade> : null
                    }
                    {currentView === "home" ?
                        <div style={{ padding: '30px', height: '100vh', overflow: 'auto' }}>
                            <Assistants userData={userData} assistants={assistants} />
                        </div> : null
                    }

                </React.Fragment> : null
            }

        </div>
    );
};

export default InternalHome;
