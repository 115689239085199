// MessageList.jsx
import React from 'react';
import Message from './MessageBubble';
import styles from './CSS Modules/messagebubble.module.scss';

const MessageList = ({ messages, config }) => {
  // Check if messages is defined
  if (!messages) {
    console.error('Messages is undefined');
    return null;
  }

  // Filter out messages with role 'assistant' and sort by created_at timestamp
  let filteredAndSortedMessages = messages
    .filter(message => message.role !== 'assistant')
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  console.log(messages)
  return (
    <div>
      {filteredAndSortedMessages.length > 0 ? (
        filteredAndSortedMessages.map((messageItem, idx) => (
          <div key={idx} style={{ position: 'relative', display: 'flex', alignItems: 'start', gap: '16px' }}>
            {messageItem.role === 'assistant' ? (
              <img src={config.logo} className={styles.chatLogo} alt="Chat Logo" />
            ) : null}
            <Message key={messageItem.id} messageItem={messageItem} config={config} />
          </div>
        ))
      ) : null}
    </div>
  );
};

export default MessageList;
