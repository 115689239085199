import React, { useState, useEffect } from 'react';
import { app, auth, db } from './firebaseApp/firebaseApp';
import { signInWithEmailAndPassword, sendPasswordResetEmail, onAuthStateChanged } from "firebase/auth";
import InternalHome from '../InternalHome/InternalHome';
import { Navigate } from "react-router-dom";
import './login.css';


const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [showPasswordResetInput, setShowPasswordResetInput] = useState(false);
  const [emailReset, setEmailReset] = useState(null)
  const [resetEmailSent, setResetEmailSent] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);

      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);



  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          const token = user.getIdToken();
          localStorage.setItem('token', token);

          setUser(user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode)
          if (errorCode === 'auth/wrong-password') {
            setError('Opps, Wrong Email and Password Combination. Please Try Again');
          }
          if (errorCode === "auth/too-many-requests") {
            setError('Too many login attempts. Please try again later');
          }
          if (errorCode === "auth/user-not-found") {
            setError('User not found in our records');
          }

        });
    } catch (error) {
      setError(error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, emailReset);
      setShowPasswordResetInput(false)
      setResetEmailSent(true);
    } catch (error) {
      setError(error.message);
    }
  };


  if (resetEmailSent) {
    return (
      <div className='login-wrapper'>
        <h1 style={{ color: 'white', textAlign: 'center' }}>OutRise.ai</h1>
        <div className="reset-email-sent">
          <h2>Password reset email sent</h2>
          <p>Please check your email for instructions to reset your password.</p>
          <button className="primary-btn" onClick={() => setResetEmailSent(false)}>Return to login</button>
        </div>
      </div>
    );
  }

  {/* <InternalHome userAuth={user} /> */ }

  return (
    <div>
      {user !== null ?
        <InternalHome/> :
        <div className='login-wrapper'>
          <h1 style={{ color: 'white', textAlign: 'center' }}>OutRise.ai</h1>
          <div className="login-form">
            <form onSubmit={handleLogin}>
              <h2 style={{ fontSize: '20px' }}>Login</h2>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Login</button>
              {error && <div className="error">{error}</div>}
            </form>

            <div className="register-link">
              Don't have an account? <a href="/signup">Register a free account</a>.
            </div>
            <div className="forgot-password-link">
              <a href="#" onClick={() => { setShowPasswordResetInput(true) }} >Forgot your password?</a>
            </div>
          </div>
        </div>
      }
    </div>


  );
};

export default LoginForm;
