import React, { useState, useEffect, useRef } from 'react';
import { createSubFolder, getDocumentsInCollection } from '../../HelperFunctions/firestoreHelpers';
import './Search.css';

function SearchInput({ specArray, onFilteredArrayUpdate, createNewFolder, pathAry, curFolder, triggerRerender }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSpecArray, setFilteredSpecArray] = useState(specArray);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [listDisplay, setListDisplay] = useState("grid");
  const [submitted, setSubmitted] = useState(false);
  const inputRef = useRef(null);

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSubmitted(false)
    setSearchTerm(term);
    if (term === '') {
      setFilteredSpecArray(specArray);
      onFilteredArrayUpdate(specArray);
    } else {
      const filtered = [...specArray].filter(spec => spec.name.toLowerCase().includes(term.toLowerCase()));
      setFilteredSpecArray(filtered);
      onFilteredArrayUpdate(filtered);
    }
    setActiveIndex(-1);
  };

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 13: // enter key
        if (activeIndex >= 0) {
          setSearchTerm(filteredSpecArray[activeIndex].name);
          onFilteredArrayUpdate([filteredSpecArray[activeIndex]]);
          setFilteredSpecArray([filteredSpecArray[activeIndex]]);
          setActiveIndex(-1);
          setSubmitted(true)
        }
        break;
      case 38: // up arrow key
        setActiveIndex(activeIndex - 1);
        break;
      case 40: // down arrow key
        setActiveIndex(activeIndex + 1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const createAndUpdateFolders=async()=>{
    let subfolder_path=await createSubFolder(curFolder.path, { name: 'Untitled Folder', type: "Private", permissions: [], isRoot:false }); 
    let subcollectionPath=await getDocumentsInCollection(subfolder_path)
    
    triggerRerender(curFolder)
  }

  return (
    <div className="search-input-container" >
      <button className='primary-btn'>New Assistant</button>
      <input
        type="text"
        id="search-input-collections"
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        placeholder="Search by name"
        ref={inputRef}
      />
      {filteredSpecArray.length > 0 && (
        <ul className="search-results">
          {searchTerm !== "" && !submitted ? filteredSpecArray.map((spec, index) => (
            <li
              key={spec.spec_id}
              className={index === activeIndex ? 'active' : ''}
              onClick={() => {
                setSearchTerm(spec.name);
                onFilteredArrayUpdate([spec]);
                setFilteredSpecArray([spec]);
                setActiveIndex(-1);
              }}
            >
              {spec.name}
            </li>
          )) : null}
        </ul>
      )}
    </div>
  );
}

export default SearchInput;
