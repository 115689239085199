import React, { useState, useEffect, useRef } from 'react';
import ChatCanvas from './ChatFeed/ChatCanvas';
import { app, auth, db } from '../Auth/firebaseApp/firebaseApp';
import { IoMdArrowBack } from "react-icons/io";
import { getFirestore, collection, setDoc, getPath, addDoc, getDoc, doc, query, getDocs, where } from "firebase/firestore";
import styles from './conversations.module.scss';

function Conversations() {
    const [chats, setChats] = useState(null);
    const [selectedThread, setSelectedThread] = useState(null);
    const [chatFeed, setChatFeed] = useState(null);
    const [assistantDoc, setAssitantDoc] = useState(null);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const assistant_doc_id = urlParams.get('id');

        if (assistant_doc_id) {
            getThreadDoc(assistant_doc_id)
            getAssistantDoc(assistant_doc_id)

        }


    }, []);

    const getAssistantDoc = async (id) => {
        try {
            const docRef = doc(db, 'assistants', id);
            const assistantDocSnapshot = await getDoc(docRef);
            setAssitantDoc(assistantDocSnapshot.data())
        } catch (error) {
            console.error('Error fetching research document:', error.message);
        }
    };

    const getThreadDoc = async (id) => {
        try {
            const q = query(collection(db, "threads"), where("assistant_doc_id", "==", id));
            const querySnapshot = await getDocs(q);
            let chatData = []
            querySnapshot.forEach((doc) => {

                chatData.push(doc.data())
            });
            //console.log("chatData", chatData)
            loadThread(chatData[0].thread_id)

            setChats(chatData)
        } catch (error) {
            console.error('Error fetching research document:', error.message);
        }
    };


    const loadThread = async (threadId) => {
        setSelectedThread(threadId)
        const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/getThread/" + threadId;

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            console.log('Chat Messages:', data);
       
            setChatFeed(data)

        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    function formatDate(timestamp) {
        const milliseconds = timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6);
        const date = new Date(milliseconds);

        // Get month and day
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = date.getDate().toString().padStart(2, '0');

        // Get hours, minutes, and seconds
        let hours = date.getHours();
        const amPM = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12; // Convert to 12-hour format
        const minutes = date.getMinutes().toString().padStart(2, '0');


        // Construct the formatted string
        const formattedDateTime = `${month}/${day}`;
        return formattedDateTime;
    }

    function formatTime(timestamp) {
        const milliseconds = timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6);
        const date = new Date(milliseconds);

        // Get month and day
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = date.getDate().toString().padStart(2, '0');

        // Get hours, minutes, and seconds
        let hours = date.getHours();
        const amPM = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12; // Convert to 12-hour format
        const minutes = date.getMinutes().toString().padStart(2, '0');


        // Construct the formatted string
        const formattedDateTime = `${hours}:${minutes}${amPM}`;

        return formattedDateTime;
    }

    return (
        <div className={styles.contentWrapper} >
            <div className={styles.leftRail}>
                <div style={{ color: 'white', fontSize: '16px', display: 'flex', marginBottom: '18px', alignItems: 'center', gap: '10px', cursor: 'pointer' }} onClick={()=>{window.location.href='/'}}>
                    <IoMdArrowBack  style={{fontSize: '20px'}}/>
                    <div className={styles.return}>Return to Assistants</div>
                </div>


                <div className={styles.leftRailHeading}>Conversations</div>
                <div className={styles.chatListContaier}>
                    {(chats && chats.length > 0) ? chats.map((item, idx) => (
                        <div key={idx} className={styles.converationItem} onClick={() => { loadThread(item.thread_id)}} style={{outline: selectedThread===item.thread_id ? `1px solid ${assistantDoc.config.color}`: '1px solid rgb(78, 78, 82)'}}>
                            <div className={styles.date}>{formatDate(item.created_at)}</div>
                            <div className={styles.time}>{formatTime(item.created_at)}</div>
                            <div className={styles.initMsg}>{item?.msg}</div>
                        </div>
                    )) : null}
                </div>
            </div>
            {chatFeed ?
                <div className={styles.feedContainer}>
                    <ChatCanvas messageAry={chatFeed.data} config={assistantDoc.config}></ChatCanvas>
                </div> : null
            }

        </div>
    );
}

export default Conversations;
