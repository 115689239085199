import React, { useState, useEffect } from 'react';
import { db, storage } from '../Auth/firebaseApp/firebaseApp'
import { getUserData } from '../HelperFunctions/firestoreHelpers';
import InternalHomeHeader from '../InternalHome/InternalHomeHeader';
import { setDoc, doc, } from "firebase/firestore";
import UFD from '../UFD/ufd'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import './profilepage.css';

const ProfilePage = ({ match }) => {
    const uid = window.location.href.split('/').pop()
    const [userData, setUserData] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [profilePic, setProfilePic] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        retriveUserData()
    }, [uid]);

    const retriveUserData = async () => {
        let data = await getUserData(uid)
        setUserData(data)
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setCompany(data.company);
        setEmail(data.email);
        setPhone(data.phone);
        setProfilePic(data.profile_pic);
    }

    const uploadProfilePicture = async (event) => {
        setIsUploading(true)
        const file = event.target.files[0];

        // Check if file is a PNG or JPEG image
        if (!["image/png", "image/jpeg"].includes(file.type)) {
            console.log("Invalid file type. Only PNG and JPEG images are allowed.");
            return;
        }

        // Check if file size is under 2MB
        if (file.size > 2 * 1024 * 1024) {
            console.log("File size exceeds 2MB limit.");
            return;
        }

        // Upload file to Firebase Storage
        const storage = getStorage();

        const storageRef = ref(storage, `users_content/${email}/profile_pic/${file.name}`);
        await uploadBytes(storageRef, file);

        // Retrieve download URL
        const downloadURL = await getDownloadURL(storageRef);
        console.log(`Profile picture uploaded. Download URL: ${downloadURL}`);
        updateUserData(userData.uid, downloadURL)
    };

    const updateUserData = async (uid, profile_pic) => {
        await setDoc(doc(db, "users", uid), {
            profile_pic: profile_pic
        }, { merge: true });
        setIsUploading(false)
        window.location.reload(true)
    }

    return (
        <div style={{ height: '100vh', overflow: 'auto', marginBottom: '60px' }}>
            {userData ?
                <React.Fragment>
                    {isUploading ?
                        <UFD msg={"Upload in progress...Hang Tight"}></UFD> : null
                    }
                    <InternalHomeHeader userData={userData} />
                    <div className="profile-container">
                        <div className="profile-header">
                            <h2>{firstName} {lastName}</h2>

                            {profilePic === "initials" ?
                                <div className="profile-initals">
                                    {firstName.charAt(0) + lastName.charAt(0)}
                                </div> :
                                <img src={profilePic} alt="Profile" />
                            }

                            <label htmlFor="profile-pic-upload" className="profile-pic-upload-label">
                                Update Profile Image
                                <input
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg"
                                    id="profile-pic-upload"
                                    onChange={uploadProfilePicture}
                                />
                            </label>

                            {/* <button onClick={handleUpdateProfile}>Update Profile</button> */}
                        </div>
                        <div className="profile-info">
                            <h3>Personal Information</h3>
                            <p>{firstName + " " + lastName}</p>
                            <p>{"Email: " + email}</p>
                            <p>{"Phone: " + phone}</p>
                        </div>
                    </div>
                </React.Fragment> :
                null
            }
        </div>
    );
};

export default ProfilePage;
