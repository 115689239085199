import React, { useState, useCallback, useEffect } from 'react';
import { app, auth, db } from '../Auth/firebaseApp/firebaseApp';
import { getFirestore, collection, setDoc, getPath, addDoc, getDoc, doc, query, getDocs, where } from "firebase/firestore";
import SearchInput from './Search/Search';
import styles from './assistants.module.scss';
import { FaCode } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";

const Assistants = ({ userData, assistants }) => {
  const [draggedJSON, setDraggedJSON] = useState('#666');
  const [specs, setSpecs] = useState([])
  const [folders, setFolders] = useState([])
  const [rerenderContent, setRerenderContent] = useState(false)
  const [curFolder, setCurFolder] = useState(null)
  const [pathAry, setPathAry] = useState([])
  const [filteredFolders, setFilteredFolders] = useState([])
  const [filteredSpecs, setFilteredSpecs] = useState([])
  const [collectionDisplay, setCollectionDisplay] = useState('grid');
  console.log(assistants)

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathname = url.pathname.slice(1)
    getThreads(pathname);
  }, [assistants])



  const getThreads = async (assistant_doc_id) => {
    const q = query(collection(db, "threads"), where("assistant_doc_id", "==", assistant_doc_id));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
    });
  };


  function sortByField(data, field, reverse = false) {
    return data.sort((a, b) => {
      let comparison = 0;
      if (a[field] < b[field]) {
        comparison = -1;
      }
      if (a[field] > b[field]) {
        comparison = 1;
      }
      return reverse ? comparison * -1 : comparison;
    });
  }


  const handleSearchUpdate = (filteredArray) => {
    setSpecs(filteredArray);
  };

  const handleListDisplayUpdate = (display) => {
    setCollectionDisplay(display)
  };

  const triggerRerender = async (folder) => {

    if (folder) {
      setCurFolder(folder)
      setRerenderContent(!rerenderContent)

    } else {
      setCurFolder(null)
      setRerenderContent(!rerenderContent)
    }

  }

  return (
    <div>
      <SearchInput
        specArray={filteredSpecs}
        pathAry={pathAry}
        curFolder={curFolder}
        triggerRerender={triggerRerender}
        onFilteredArrayUpdate={handleSearchUpdate}
        onListDisplayUpdate={handleListDisplayUpdate}
      />
      <div style={{ display: 'flex' }}>
        <a style={{ color: "white", marginBottom: '14px', fontSize: '16px' }} href="/home" onClick={() => { setCurFolder(null) }}>My Assistants</a>
        {pathAry && pathAry.length > 0 ? pathAry.map((folder, index) => (
          <div style={{ display: 'flex', marginLeft: '4px', }}>

            <a key={index} href={"/" + folder.path} style={{ color: "white", marginBottom: '14px', fontSize: '16px' }}>{folder.name}</a>
          </div>
        )) : null}
      </div>
      <div className={styles.contentWrapper}>
        {(assistants && assistants.length > 0) ? assistants.map((item, idx) => (
          <div key={idx} className={styles.card}>
           
            <img className={styles.chatLogo} src={item?.config.logo}></img>
            <div className={styles.chatName}>{item?.config.chatName}</div>
           
            <div className={styles.chatURL}>
              {item.assistant.name}
            </div>
            <div className={styles.iconTextLockup}>
              <FaRegEdit style={{color:'#5966db', fontSize: '16px'}} />
              <div className={styles.btnLink}>Edit Training Data</div>
            </div>

            <div className={styles.iconTextLockup}>
              <FaCode style={{color:'#5966db', fontSize: '16px'}}/>
              <div className={styles.btnLink}>Embed Code</div>
            </div>
            {/* <div>Status: Pending Subscription</div> */}
            <div className={styles.viewConversationBtn} onClick={()=>{window.location.href=`/conversations?id=${item.docId}`}}>View Conversations</div>

          </div>
        )) : null}

        <div style={{ height: '300px' }}></div>
      </div>

    </div >
  );

};

export default Assistants;
