import React, { useState, useEffect } from 'react';
import './signup.css';
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { getFirestore, collection, addDoc, setDoc, getDoc, updateDoc, doc, getDocs, where, query } from "firebase/firestore";
import firebaseConfig from '../firebaseConfig';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);
const RegistrationForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [assistantId, setAssistantId] = useState(null)
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [company, setCompany] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const assistant_doc_id = urlParams.get('assistant_id');
        if (assistant_doc_id) {
            setAssistantId(assistant_doc_id)
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const accountObj = await createAccount()
        console.log(accountObj)
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // ...

                sendEmailVerification(auth.currentUser)
                    .then(() => {
                        // Email verification sent!
                        const createUser = async () => {
                            try {
                                await updateProfile(auth.currentUser, {
                                    displayName: firstName + " " + lastName
                                });
                                const userRef = doc(db, "users", auth.currentUser.uid);
                                await setDoc(userRef, {
                                    first_name: firstName,
                                    last_name: lastName,
                                    email: email,
                                    phone: phone,
                                    company: company,
                                    profile_pic: 'initials',
                                    account_id: accountObj.data.docId,
                                    uid: auth.currentUser.uid,
                                    join_date: new Date()
                                });
                                //I want to update the doc in the accounts collection, I want to add the auth.currentUser.uid to the existing users ary 
                                // Fetch the existing users array
                                const accountRef = doc(db, "accounts", accountObj.data.docId);
                                const accountDoc = await getDoc(accountRef);
                                const existingUsers = accountDoc.data().users || [];

                                // Append the new UID
                                existingUsers.push(auth.currentUser.uid);

                                // Update the document in the "accounts" collection
                                await updateDoc(accountRef, {
                                    users: existingUsers
                                });
                                setIsSubmitted(true)
                                console.log("User Document written with ID: ", auth.currentUser.uid);
                            } catch (e) {
                                console.error("Error Creating User: ", e);
                            }
                        };
                        createUser();
                    });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                if (errorCode === "auth/email-already-in-use") {
                    setErrorMsg("An account with this email already exists!")
                }
                console.log(error)
                // ..
            });

        //Need to add the user id to the users array within accounts
    };

    const validateForm = () => {
        if (firstName.length > 0 && lastName.length > 0 && email.length > 0 && phone.length > 0 && password.length > 0 && company.length > 0) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    };

    const createAccount = async () => {
        //Check to see if account exists
        const existingAccountRef = collection(db, 'accounts');
        const querySnapshot = await getDocs(query(existingAccountRef, where('name', '==', company)));
        if (!querySnapshot.empty) {
            // Account with the same name already exists
            const existingAccountData = querySnapshot.docs[0].data();
            let objStruct = {}
            objStruct.data = existingAccountData
            console.log('Account with the name already exists:', objStruct);
            return objStruct; // You can return null or handle the case accordingly
        }
        try {
            let newAccount = {}
            newAccount.name = company
            newAccount.users = []
            newAccount.keyRef = ""
            newAccount.billing = "Credit Card"
            newAccount.assistants = []
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/create-account/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newAccount),
            });

            let result = await response.json();
            //console.log("result", result)
            if (assistantId) {
                const urlCollection = collection(db, 'assistants');
                const docRef = doc(urlCollection, assistantId);
                const docSnapshot = await getDoc(docRef);
                //console.log("here", docSnapshot)
    
                await updateDoc(docRef, {
                    account_id: result.data.docId
                });
            }

            return result
        } catch (error) {
            console.error('Error creating account:', error);
        }
    };

    return (
        <div className="register-wrapper">
            <div className='content-wrapper'>
                <h1 style={{ color: '#f4f4f4', textAlign: 'center' }}>OutRise.ai</h1>
                {isSubmitted ? (
                    <div style={{ textAlign: 'center' }}>
                        <h2 style={{ color: 'white' }}>Thank you for signing up!</h2>
                        {/* <p style={{color:'white'}}>Click here to log in to your account</p> */}
                        <button className="login-btn-su" onClick={() => { window.location.href = "./" }}>Log In</button>
                        {/* <a href="/signin">Click here to sign in</a> */}
                    </div>
                ) : (
                    <div className="registration-form">
                        <h2>Create an Account</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="first-name">First Name:</label>
                                <input
                                    type="text"
                                    id="first-name"
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                        validateForm();
                                    }}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="last-name">Last Name:</label>
                                <input
                                    type="text"
                                    id="last-name"
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                        validateForm();
                                    }}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        validateForm();
                                    }}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone:</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                        validateForm();
                                    }}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password:</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        validateForm();
                                    }}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="company">Account Name (Company):</label>
                                <input
                                    type="text"
                                    id="company"
                                    placeholder=''
                                    value={company}
                                    onChange={(e) => {
                                        setCompany(e.target.value)
                                        validateForm();

                                    }}
                                />
                            </div>
                            <button type="submit" disabled={!isValid}>Register</button>
                            {errorMsg ?
                                <p style={{ color: 'red' }}>{errorMsg}</p> : null
                            }

                        </form>
                    </div>
                )
                }

            </div>

        </div>
    );
};

export default RegistrationForm;
